import React, { useState, useEffect } from "react";
import fetch from "cross-fetch";
import qs from "qs";
import format from "date-fns/format";
import frLocale from "date-fns/locale/fr";
import { matchPath, useLocation, Link } from "react-router-dom";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import store from "store2";
import Cookies from "universal-cookie";
import addDays from "date-fns/addDays";

const cookies = new Cookies();

//TODO: ajouter des tests @low

// LOCAL STORE

export const getSlugId = (slug) => slug.slice(-22);

export const mergeBySlug = (bySlug, entities) =>
  Object.keys(entities).reduce((acc, currentValue) => {
    acc[currentValue] = { ...bySlug[currentValue], ...entities[currentValue] };
    return acc;
  }, {});

export const getLocalUser = () => {
  if (typeof localStorage !== "undefined") {
    return store("user") || {};
  }

  return {};
};

export const getLocalNewsletters = () => {
  if (typeof localStorage !== "undefined") {
    const user = getLocalUser();
    return user.newsletters || {};
  }

  return {};
};

export const addLocalNewsletters = (email, newsletters) => {
  if (typeof localStorage !== "undefined") {
    const user = getLocalUser();
    return store("user", {
      ...user,
      email,
      newsletters: { ...user.newsletters, ...newsletters },
    });
  }
};

export const getLocalPetitions = () => {
  if (typeof localStorage !== "undefined") {
    const user = getLocalUser();
    return user.petitions || {};
  }

  return {};
};

export const addLocalPetitions = (email, petitions) => {
  if (typeof localStorage !== "undefined") {
    const user = getLocalUser();
    return store("user", {
      ...user,
      email,
      petitions: { ...user.petitions, ...petitions },
    });
  }
};

export const getLocalDonations = () => {
  if (typeof localStorage !== "undefined") {
    const user = getLocalUser();
    return user.donations || {};
  }

  return {};
};

export const addLocalDonations = (donations) => {
  if (typeof localStorage !== "undefined") {
    const user = getLocalUser();
    return store("user", {
      ...user,
      donations: { ...user.donations, ...donations },
    });
  }
};

const AccountCookieName = "api_account";

const getAccountTokenCookieOptions = () => ({
  expires: addDays(new Date(), 30),
  domain: process.env.NODE_ENV === "production" && ".lemediatv.fr",
});

export const setAccountToken = (token) => {
  accountToken = token;

  cookies.set(AccountCookieName, accountToken, getAccountTokenCookieOptions());
};

export const setSsrAccountToken = (res, ssrAccountToken) => {
  accountToken = ssrAccountToken;

  res.cookie(AccountCookieName, accountToken, getAccountTokenCookieOptions());
};

export const initAccountToken = (ssrHeaderCookies) => {
  if (ssrHeaderCookies) {
    const ssrCookies = new Cookies(ssrHeaderCookies);
    accountToken = ssrCookies.get(AccountCookieName);
  } else {
    accountToken = cookies.get(AccountCookieName);
  }

  return accountToken;
};

export const removeAccountToken = () => {
  cookies.remove(AccountCookieName);
  accountToken = undefined;
};

let accountToken;

// LAYOUT

export const getHelmet = (title, description, pathname, image) => [
  title && <title key="head-title">{title}</title>,
  description && (
    <meta key="meta-description" name="description" content={description} />
  ),
  <link
    key="canonical"
    rel="canonical"
    href={process.env.RAZZLE_HOSTNAME + pathname}
  />,
  <meta
    key="og:title"
    property="og:title"
    content={
      title
        ? `${title} | Le Média`
        : "Le Média, une information au service du bien commun"
    }
  />,
  description && (
    <meta
      key="og:description"
      property="og:description"
      content={description}
    />
  ),
  image && <meta key="og:image" property="og:image" content={image} />,
  <meta
    key="og:url"
    property="og:url"
    content={process.env.RAZZLE_HOSTNAME + pathname}
  />,
];

export const initMenuPropsWithPathname = (pathname) => {
  let isTransparent,
    isShadowDisabled = false;
  if (
    matchPath(pathname, {
      path: "/",
      exact: true,
    })
  ) {
    isTransparent = true;
    isShadowDisabled = true;
  }

  if (
    matchPath(pathname, {
      path: [
        "/emissions/:slug",
        "/emissions/:slug/page/:page",
        "/emissions/:program/:slug",
        "/emissions/:program/:slug/:previewToken",
        "/podcasts/:slug",
        "/podcasts/:slug/page/:page",
        "/podcasts/:podcast/:slug",
        "/podcasts/:podcast/:slug/:previewToken",
        "/soutien/abonnement",
        "/soutien/abonnement/coordonnees",
        "/soutien/abonnement/paiement",
        "/soutien/socio",
        "/soutien/socio/coordonnees",
        "/soutien/socio/paiement",
      ],
      exact: true,
    })
  ) {
    isShadowDisabled = true;
  }

  return { isTransparent, isShadowDisabled };
};

export const initFooterPropsWithPathname = (pathname) => {
  let isLetterHidden = false;
  let isNewsletterHidden = false;

  if (
    matchPath(pathname, {
      path: "/",
      exact: true,
    })
  ) {
    isLetterHidden = true;
  }

  if (
    matchPath(pathname, {
      path: "/newsletters",
      exact: true,
    })
  ) {
    isNewsletterHidden = true;
  }

  return { isLetterHidden, isNewsletterHidden };
};

export const shouldHideStickyDonation = (pathname) =>
  matchPath(pathname, {
    path: ["/soutien"],
    exact: true,
  });

export const shouldHideCampaign = (pathname) =>
  matchPath(pathname, {
    path: ["/soutien"],
    exact: true,
  });

// WITH HOOKS

export const LayoutEffectSSR = ({ children }) => {
  const [showChild, setShowChild] = useState(false);

  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    return null;
  }

  return children;
};

export const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
};

// FETCH

export const parseQuery = (search) =>
  qs.parse(search, { ignoreQueryPrefix: true });

const stringifyQuery = (query) => qs.stringify(query, { addQueryPrefix: true });

export const getData = (path, query, mask) => {
  const headers = {};
  if (mask) {
    headers["x-fields"] = mask;
  }

  if (accountToken) {
    headers.Authorization = "Bearer " + accountToken;
  }

  return fetch(`${process.env.RAZZLE_API_URL}${path}${stringifyQuery(query)}`, {
    headers,
  });
};

export const fetchShopData = (path, options = {}) => {
  const headers = {};
  if (options.method === "POST" || options.method === "PUT") {
    headers["Content-Type"] = "application/json";
  }

  if (accountToken) {
    headers.Authorization = "Bearer " + accountToken;
  }

  return fetch(`${process.env.RAZZLE_SHOP_API_URL}${path}`, {
    ...options,
    headers: {
      ...headers,
      ...options.headers,
    },
  });
};

//HTML

export const parseHTML = (html) =>
  parse(
    sanitizeHtml(html, {
      allowedTags: [
        ...sanitizeHtml.defaults.allowedTags,
        "h1",
        "h2",
        "figure",
        "img",
        "figcaption",
        "span",
        "sup",
      ],
      allowedAttributes: {
        ...sanitizeHtml.defaults.allowedAttributes,
        div: ["class"],
        p: ["class"],
        span: ["class"],
        blockquote: ["class", "align"],
        figure: ["class"],
        img: [...sanitizeHtml.defaults.allowedAttributes.img, "alt"],
        iframe: [
          "src",
          "width",
          "height",
          "frameborder",
          "webkitallowfullscreen",
          "mozallowfullscreen",
          "allowfullscreen",
        ],
      },
    })
  );

// AUTHOR

export const getAuthorsPresentation = (authors) => {
  let authorsNames, authorsDescription;
  if (authors.length === 1) {
    authorsNames = authors[0].screenname;
    authorsDescription = authors[0].description || (
      <>
        Vous pouvez retrouver tous les contenus de {authors[0].screenname} en
        consultant <Link to={`/auteurs/${authors[0].slug}`}>sa page</Link>.
      </>
    );
  } else if (authors.length === 2) {
    authorsNames = `${authors[0].screenname} et ${authors[1].screenname}`;
    authorsDescription = (
      <>
        {" "}
        Retrouvez les contenus de ces auteurs&nbsp;:&nbsp;page de{" "}
        <Link to={`/auteurs/${authors[0].slug}`}>
          {authors[0].screenname}
        </Link>{" "}
        et page de{" "}
        <Link to={`/auteurs/${authors[1].slug}`}>{authors[1].screenname}</Link>.
      </>
    );
  } else if (authors.length === 3) {
    authorsNames = `${authors[0].screenname}, ${authors[1].screenname} et ${authors[2].screenname}`;
    authorsDescription = (
      <>
        {" "}
        Retrouvez les contenus de ces auteurs&nbsp;:&nbsp;page de{" "}
        <Link to={`/auteurs/${authors[0].slug}`}>{authors[0].screenname}</Link>,
        page de{" "}
        <Link to={`/auteurs/${authors[1].slug}`}>{authors[1].screenname}</Link>{" "}
        et page de{" "}
        <Link to={`/auteurs/${authors[2].slug}`}>{authors[2].screenname}</Link>.
      </>
    );
  } else {
    authorsNames = `${authors[0].screenname}, ${authors[1].screenname}, ${authors[2].screenname} et ${authors[3].screenname}`;
    authorsDescription = (
      <>
        {" "}
        Retrouvez les contenus de ces auteurs&nbsp;:&nbsp;page de{" "}
        <Link to={`/auteurs/${authors[0].slug}`}>{authors[0].screenname}</Link>,
        page de{" "}
        <Link to={`/auteurs/${authors[1].slug}`}>{authors[1].screenname}</Link>,
        page de{" "}
        <Link to={`/auteurs/${authors[2].slug}`}>{authors[2].screenname}</Link>{" "}
        et page de{" "}
        <Link to={`/auteurs/${authors[3].slug}`}>{authors[3].screenname}</Link>.
      </>
    );
  }

  return {
    names: authorsNames,
    description: authorsDescription,
  };
};

// VIDEO

export const getYoutubeVideoId = (url) => {
  const match = url.match(
    /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/
  );

  return match ? match[1] : false;
};

// STRING

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

// DATE

export const formatDate = (date, dateFormat = "d MMMM yyyy - H'H'mm") =>
  format(new Date(date), dateFormat, { locale: frLocale });

// LINK

export const getStoryLink = (canonicalUrl) =>
  canonicalUrl.replace(/^https?:\/\/[\w.]*/, "");

export const getPodcastLink = (canonicalUrl) =>
  getStoryLink(canonicalUrl).replace(/^\/[\w-]*/, "/podcasts");

// SHOP

export const getSubscriptionItem = (product_id) => ({
  product_type: "SUBSCRIPTION_PLAN",
  product_id,
  quantity: 1,
});

export const getSharesItem = (quantity) => ({
  product_type: "LEMEDIATV_SHARES_SCIC",
  product_id: "41a607e4-02cd-4995-bb5b-1c4a25878fb4",
  quantity,
});

export const roles = {
  GUEST: "invité",
  SUBSCRIBER: "abonné",
  SOCIO: "socio",
  ADMINISTRATOR: "admin",
  UNLIMITED: "compte illimité",
};

export const paymentMethods = {
  SEPA: "prélèvement SEPA",
  CREDIT_CARD: "carte bancaire",
  BANK_TRANSFER: "virement bancaire",
  CHECK: "chèque",
};
