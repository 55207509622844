import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Switch, useLocation, useHistory } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { animateScroll as scroll } from "react-scroll";
import { Helmet } from "react-helmet";
import { Waypoint } from "react-waypoint";
import Modal from "react-modal";
import fetch from "cross-fetch";
import { useSelector } from "react-redux";
import { ThemeProvider as MUIThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";
import numeral from "numeral";
import "numeral/locales/fr";

import "./styles.css";
import routes from "./routes";
import { getAuth } from "./configureStore";
import {
  getLocalUser,
  getHelmet,
  initMenuPropsWithPathname,
  initFooterPropsWithPathname,
  shouldHideStickyDonation,
  // shouldHideCampaign,
} from "./utilities";
import { gradients, muiDefaultTheme } from "./theme";
import Menu from "./components/menu/Menu";
// import CampaignMenu from "./components/campaign/CampaignMenu";
import BurgerMenu from "./components/menu/BurgerMenu";
import StickyDonation from "./components/StickyDonation";
// import CampaignSticky from "./components/campaign/CampaignSticky";
import ErrorBoundary from "./components/ErrorBoundary";
import Footer from "./components/menu/Footer";
import share from "./assets/share.png";
import btnGoToTop from "./assets/btn-top-red.svg";

// import CampaignModal from "./components/campaign/CampaignModal";

// Accessibility with react-modal
Modal.setAppElement("#root");

numeral.locale("fr");

const session = {
  date: new Date(),
  navigation: [],
};

const Container = styled.div`
  background: #ff4360;
  width: 100%;

  ${(props) =>
    props.isMenuOpen &&
    `
      overflow: hidden;
    `};

  ${(props) =>
    !props.outline &&
    `
  * {
    outline: none;
  }
  `}
`;

const PageContainer = styled.div`
  background: white;
  position: relative;
  transition: 0.3s;
  z-index: 1;

  ${(props) =>
    props.isMenuOpen &&
    `
      height: 100vh;
      overflow: hidden;
      transform: translateX(320px)
    `};
`;

const Overlay = styled.div`
  background: ${gradients.redToPurple("to right bottom")};
  content: "";
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: 0.3s;
  visibility: hidden;
  width: 100%;
  z-index: 15000;

  ${(props) =>
    props.isMenuOpen &&
    `
      opacity: 0.95;
      visibility: visible;
    `};
`;

const GoToTop = styled.button`
  background: url(${btnGoToTop}) rgba(229, 233, 233, 0.3) center no-repeat;
  border-radius: 50%;
  bottom: ${(props) => (props.shouldRaise ? "100px" : "46px")};
  height: 52px;
  position: fixed;
  right: 41px;
  text-indent: -9999px;
  transition: 0.3s;
  width: 52px;
  z-index: 10000;

  @media (max-width: 1023px) {
    bottom: ${(props) => (props.shouldRaise ? "85px" : "20px")};
    right: 15px;
  }

  @media (max-width: 767px) {
    bottom: ${(props) => (props.shouldRaise ? "90px" : "20px")};
  }

  :hover {
    background-color: rgba(229, 233, 233, 0.75);
  }
`;

function Root() {
  const footerRef = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [enableOutline, setEnableOutline] = useState(false);
  const [shouldRaiseGoToTop, setShouldRaiseGoToTop] = useState(false);
  const { pathname } = useLocation();
  const [currentLocation, setCurrentLocation] = useState(pathname);
  const history = useHistory();
  const auth = useSelector(getAuth);

  const unlistenHistory = history.listen((location) => {
    const matomo = window._paq;

    matomo.push(["setReferrerUrl", currentLocation]);
    setCurrentLocation(location.pathname);
    matomo.push(["setCustomUrl", currentLocation]);
    matomo.push(["setDocumentTitle", document.title]);

    // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
    matomo.push(["deleteCustomVariables", "page"]);
    matomo.push(["setGenerationTimeMs", 0]);
    matomo.push(["trackPageView"]);

    // make Matomo aware of newly added content
    const content = document.getElementById("root");
    matomo.push(["MediaAnalytics::scanForMedia", content]);
    matomo.push(["FormAnalytics::scanForForms", content]);
    matomo.push(["trackContentImpressionsWithinNode", content]);
    matomo.push(["enableLinkTracking"]);

    const user = getLocalUser();
    if (user.email) {
      session.email = user.email;
      session.navigation.push(location.pathname);
      fetch(`${process.env.RAZZLE_HOSTNAME}/session`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(session),
      });
    }
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleFocusOutline = (e) => {
        if (e.keyCode === 9) {
          setEnableOutline(true);
        }
      };

      window.addEventListener("keydown", handleFocusOutline);

      return () => {
        window.removeEventListener("keydown", handleFocusOutline);

        unlistenHistory();
      };
    }
  }, [unlistenHistory]);

  function toggleBurgerMenu() {
    setIsMenuOpen((state) => !state);
  }

  function raiseGoToTop(flag) {
    setShouldRaiseGoToTop(flag);
  }

  return (
    <ErrorBoundary>
      <MUIThemeProvider theme={muiDefaultTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
          {/*<CampaignModal defaultIsOpen={!shouldHideCampaign(pathname)} />*/}

          <Helmet
            defaultTitle="Le Média, une information au service du bien commun"
            titleTemplate="%s | Le Média"
          >
            {getHelmet(
              undefined,
              `Le Média n’appartient ni à l’Etat, ni à des milliardaires. Engagé en faveur des luttes sociales et écologiques, il offre un regard différent des médias dominants.`,
              pathname,
              process.env.RAZZLE_HOSTNAME + share
            )}
            <meta name="robots" content="index, follow" />
            <meta name="twitter:card" content="summary_large_image" />
            <link
              rel="alternate"
              type="application/rss+xml"
              title="Flux RSS | Le Média"
              href={`${process.env.RAZZLE_API_DOMAIN}/rss.xml`}
            />
          </Helmet>
          <Container outline={enableOutline} isMenuOpen={isMenuOpen}>
            {/* <CampaignMenu /> */}
            <BurgerMenu
              isOpen={isMenuOpen}
              onClick={() => toggleBurgerMenu()}
              isConnected={auth.isConnected}
              {...initMenuPropsWithPathname(pathname)}
            />
            <PageContainer isMenuOpen={isMenuOpen}>
              <Overlay isMenuOpen={isMenuOpen} onClick={toggleBurgerMenu} />
              <Menu
                {...initMenuPropsWithPathname(pathname)}
                toggleBurgerMenu={toggleBurgerMenu}
                isConnected={auth.isConnected}
              />
              {!shouldHideStickyDonation(pathname) && <StickyDonation />}
              <GoToTop
                onClick={scroll.scrollToTop}
                shouldRaise={shouldRaiseGoToTop}
              >
                Remonter en haut de la page
              </GoToTop>

              <Switch>{renderRoutes(routes)}</Switch>
              <Waypoint
                onEnter={() => raiseGoToTop(true)}
                onLeave={() => raiseGoToTop(false)}
              >
                <Footer
                  innerRef={footerRef}
                  isConnected={auth.isConnected}
                  {...initFooterPropsWithPathname(pathname)}
                />
              </Waypoint>
            </PageContainer>
          </Container>
        </MuiPickersUtilsProvider>
      </MUIThemeProvider>
    </ErrorBoundary>
  );
}

export default Root;
